
.iframe .logo img{
    width: 32px;
    /*margin: 15px 24px 15px 0;*/
    float: left;
}
.iframe .logo{
    float: left;
    width: fit-content;
    height: 32px;
    background-color: #0d0d0d;
    margin: 16px 24px 16px 0;
}
.iframe .name{
    display: inline-block;
    font-size: 18px;
    position: absolute;
    color: #ffffff;
    left:55px;
    top: 0px;
    font-weight: bold;
}

.iframe .logo_content{
    float: left;
    width: fit-content;
    height:100%;
    margin-right: 16px;
}

.iframe .div_img{
    float: left;
    margin-right:20px;
    vertical-align: middle;
}

.iframe .img{
    width: 32px;
    height: 32px;
}

.iframe .content{
    float: left;
    font-size: 25px;
    color: #ffffff;
    font-weight: bold;
}

.iframe .header_right {
    float: right;
    color: white;
}
.iframe .header_role {
    /*float: right;*/



    color: white;
    display: inline-block;
    margin-right: 8px;
}
.iframe .ant-menu-submenu-title:hover{
    color:#fff
}

.iframe {
    min-height: 100vh;
}
.layout .ant-menu-submenu-title:hover{
    color: #fff ;
}